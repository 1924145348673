<template>
    <section>
        <div id="sob-onboarding-screen"></div>
    </section>
</template>
  
  <script>
  import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
  export default {
    name: "Onboarding",
    components: {
    },
    data() {
        return {}
    },
    computed: {
    },
    mounted() {
        setCurrentPageTitle("OnBoarding Screen");
        window.OnBoardingLibrary.init([
            "sob-onboarding-screen",
            "https://app.saasonboard.com/",
            "SvbrVZCfz9uEaZu",
            "onboarding-screen-iframe"
        ]);
    },
    methods: {
    },
  };
  </script>